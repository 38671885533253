import * as React from 'react';
import { Unstable_Grid2 as Grid, Select, MenuItem, FormControl } from '@mui/material';
import logo from '../../images/festo-logo.svg';
import { useTranslation } from 'react-i18next';

function Header() {
    const { i18n } = useTranslation();

    const [language, setLanguage] = React.useState(i18n.language.substring(0, 2));

    return (
        <Grid
            px={5}
            container
            spacing={2}
            justifyContent="right"
            className="header"
        >
            <Grid display="flex" justifyContent="center" alignItems="end">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        label="Language"
                        onChange={e => { setLanguage(e.target.value); i18n.changeLanguage(e.target.value); }}
                    >
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="de">DE</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item display="flex" justifyContent="center" alignItems="center">
                <img
                    style={{ height: "30px" }}
                    src={logo}
                    alt="logo"
                />
            </Grid>
        </Grid>
    );
}

export default Header;
