import * as React from 'react';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, Link } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

function ManualDialog(props) {
    const { open, setOpen } = props;

    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="faq-dialog-title"
            aria-describedby="faq-dialog-description"
        >
            <DialogTitle id="faq-dialog-title">
                <Grid container direction="row" spacing={3} alignItems="flex-start">
                    <Grid item>
                        <Link href="#manual-faqs" color="inherit">
                            {t("Manual.FAQs")}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#manual-documentation" color="inherit">
                            {t("Manual.Documentation")}
                        </Link>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} className="main" p={3} sx={{ position: "relative", p: { xs: 0, sm: 3 } }}>
                    <Grid item xs={12} id="manual-faqs"><Typography variant="h5"><Box fontWeight={800}>{t("Manual.FAQs")}</Box></Typography></Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q0")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A0")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q1")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q2")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q3")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q4")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A4")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q5")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A5")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q6")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A6")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q7")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A7_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A7_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A7_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A7_4")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q8")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A8")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q9")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A9")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q10")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Trans i18nKey="Manual.A10" /></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q11")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A11")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Q12")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.A12")}</Typography></Grid>
                    <Grid item xs={12} id="manual-documentation"><Typography variant="h5"><Box fontWeight={800}>{t("Manual.Title1")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text1_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text1_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text1_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text1_4")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text1_5")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title2")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text2_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text2_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title3")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text3_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text3_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title4")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text4_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title5")}</Box></Typography></Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1, mx: 2 }}><Typography variant="body2">- {t("Manual.Text5_1_1")}</Typography></Box>
                            <Box sx={{ flex: 3 }}><Typography variant="body2">{t("Manual.Text5_1_2")}</Typography></Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1, mx: 2 }}><Typography variant="body2">- {t("Manual.Text5_2_1")}</Typography></Box>
                            <Box sx={{ flex: 3 }}><Typography variant="body2"><Trans i18nKey="Manual.Text5_2_2" /></Typography></Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1, mx: 2, wordBreak: "break-all", overflowWrap: "break-word", minWidth: 67 }}><Typography variant="body2">- {t("Manual.Text5_3_1")}</Typography></Box>
                            <Box sx={{ flex: 3, wordBreak: "break-all", overflowWrap: "break-word" }}><Typography variant="body2">{t("Manual.Text5_3_2")}</Typography></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title6")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text6_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text6_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text6_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title7")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Trans i18nKey="Manual.Text7_1" /></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Text7_2")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text7_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text7_4")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Trans i18nKey="Manual.Text7_5" /></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Manual.Title8")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text8_1")}</Typography></Grid>
                    <Grid item xs={12}><img src="/captcha.png" alt="captcha_faq" style={{ maxWidth: "100%" }} /></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text8_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text8_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Manual.Text8_4")}</Typography></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ManualDialog;
