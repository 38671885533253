import * as React from 'react';
import { useTranslation } from 'react-i18next';

function useDateFormat() {
    const { i18n } = useTranslation();

    const [dateFormat, setDateFormat] = React.useState(i18n.language.substring(0, 2));

    React.useEffect(() => {
        const languageDateFormatValues = {
            "en": "MM/DD/YYYY",
            "de": "DD/MM/YYYY"
        }

        let language = i18n.language.substring(0, 2);
        let newFormat = languageDateFormatValues[language];
        setDateFormat(newFormat);
    }, [i18n.language]);

    return dateFormat;
}

export default useDateFormat;
