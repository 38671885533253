import { notNullOrEmpty, equalsTrue, dateIsValid, isValidEmailOrEmpty, isValidPhoneOrEmpty } from '../../helpers/ValidationHelper';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const getNewPostFormModel = () => ({
    illnessType: { value: "", isValid: true, validators: [notNullOrEmpty] },
    firstname: { value: "", isValid: true, validators: [notNullOrEmpty] },
    lastname: { value: "", isValid: true, validators: [notNullOrEmpty] },
    shortname: { value: "", isValid: true, validators: [] },
    birthdate: { value: null, isValid: true, validators: [dateIsValid] },
    sickDateFrom: { value: null, isValid: true, validators: [dateIsValid, sickDateFromValidator] },
    sickDateTo: { value: null, isValid: true, validators: [dateIsValid, sickDateToValidator] },
    email: { value: "", isValid: true, validators: [isValidEmailOrEmpty] },
    phoneNumber: { value: "", isValid: true, validators: [isValidPhoneOrEmpty] },
    captcha: { value: "", isValid: true, validators: [] },
    notificationImageFile: { value: null, isValid: true, validators: [] },
    confirmedLegal: { value: false, isValid: true, validators: [equalsTrue] },
});

const dateFromLimit = dayjs().subtract(15, 'day');
const dateToLimit = dayjs().add(43, 'day');

const sickDateFromValidator = (value, model) => {
    let dateFrom = dayjs(value);
    let dateTo = dayjs(model.sickDateTo.value);
    let isValid = dateFrom.isSameOrAfter(dateFromLimit) && (!model.sickDateTo.value || dateFrom.isSameOrBefore(dateTo));
    if (isValid && dateTo.isSameOrBefore(dateToLimit))
        model.sickDateTo.isValid = true;
    if (model.illnessType.value === "IllWithoutNotification") {
        let diff = dateTo.diff(dateFrom);
        if (!isNaN(diff) && diff > 172800000) {
            model.sickDateTo.isValid = false;
            model.sickDateTo.validationMessage = "Validation.Maximum3DaysInCaseOfSickLeaveWithoutCertificate";
            isValid = false;
        }
    }
    return isValid;
}

const sickDateToValidator = (value, model) => {
    let dateFrom = dayjs(model.sickDateFrom.value);
    let dateTo = dayjs(value);
    let isValid = dateTo.isSameOrBefore(dateToLimit) && (!model.sickDateFrom.value || dateFrom.isSameOrBefore(dateTo));
    if (isValid && dateFrom.isSameOrAfter(dateFromLimit))
        model.sickDateFrom.isValid = true;
    if (model.illnessType.value === "IllWithoutNotification") {
        let diff = dateTo.diff(dateFrom);
        if (!isNaN(diff) && diff > 172800000) {
            model.sickDateFrom.isValid = false;
            model.sickDateTo.validationMessage = "Validation.Maximum3DaysInCaseOfSickLeaveWithoutCertificate";
            isValid = false;
        }
    }
    return isValid;
}

export const illnessTypes = ["Ill", "IllWithoutNotification", "WorkAccident"];