import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Box } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

function LegalDialog(props) {
    const { open, setOpen } = props;

    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="lg"
            aria-labelledby="legal-dialog-title"
            aria-describedby="legal-dialog-description"
        >
            <DialogTitle id="legal-dialog-title">
                {t("Legal.Title0")}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} className="main" p={3} sx={{ position: "relative", p: { xs: 0, sm: 3 } }}>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title1")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Trans i18nKey="Legal.Text1" /></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title2")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title3")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title4")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title5")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text5_1")}</Typography></Grid>
                    <ul>
                        <li><Typography variant="body2">{t("Legal.Text5_1_1")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_1_2")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_1_3")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_1_4")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_1_5")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_1_6")}</Typography></li>
                    </ul>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text5_2")}</Typography></Grid>
                    <ul>
                        <li><Typography variant="body2">{t("Legal.Text5_2_1")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_2_2")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_2_3")}</Typography></li>
                        <li><Typography variant="body2">{t("Legal.Text5_2_4")}</Typography></li>
                    </ul>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title6")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text6_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text6_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title7")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text7")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title8")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text8")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title9")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text9_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text9_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title10")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text10_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text10_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title11")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text11_1")}</Typography></Grid>
                    <Grid item xs={12}><a href={"mailto:" + t("Legal.Text11_2")} target="_top">{t("Legal.Text11_2")}</a></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text11_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2"><Box fontWeight={800}>{t("Legal.Title12")}</Box></Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text12_1")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text12_2")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text12_3")}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="body2">{t("Legal.Text12_4")}</Typography></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LegalDialog;
