import React from 'react';
import './Spinner.css';

export default function Spinner(props) {
    const { open } = props;

    return (
        <div
            style={{ display: open ? "unset" : "none" }}
            className="loader-spinner-base"
        >
            <div className="loader-spinner-overlay"></div>
            <div className="loader-spinner-container">
                <div className="loader-spinner-circle-container">
                    <div className="loader-spinner-circle-outer"></div>
                    <div className="loader-spinner-circle-center"></div>
                    <div className="loader-spinner-circle-inner"></div>
                </div>
                <div className="loader-spinner-text">Loading...</div>
            </div>
        </div>
    );
}