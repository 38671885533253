import * as React from 'react';

export const useScript = (id, url, innerHTML) => {
    React.useEffect(() => {
        const existingElement = document.getElementById(id);
        if (existingElement === null) {

            const script = document.createElement('script');
            script.id = id;
            script.async = false;

            if (url !== null)
                script.src = url;
            if (innerHTML !== null)
                script.innerHTML = innerHTML;

            document.body.appendChild(script);
        }
    }, [id, url, innerHTML]);
};
