import * as React from 'react';
import { Unstable_Grid2 as Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" className="footer" mt={5}>
            <Grid container>
                <Typography color="#A3B2BC">
                    &copy; {new Date().getFullYear()} Festo Inc. All rights reserved
                </Typography>
            </Grid>
            <Grid container>
                <Grid item>
                    <Link href={t("ImprintLink")} underline="none" color="secondary" variant="body1">{t("Imprint")}</Link>
                </Grid>
                <Grid item>
                    <Link href={t("DataPrivacyLink")} underline="none" color="secondary" variant="body1">{t("DataPrivacy")}</Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Footer;
