import { createTheme } from '@mui/material';

export const mainTheme = createTheme({
    typography: {
        fontFamily: [
            'MetaPro',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: "#0091DC",
        },
        secondary: {
            main: "#A3B2BC"
        },
    }
});