import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^\d+$/;

export const isValidEmailOrEmpty = val => !notNullOrEmpty(val) || emailRegex.test(val);

export const isValidPhoneOrEmpty = val => !notNullOrEmpty(val) || phoneRegex.test(val);

export const notNullOrEmpty = val => val ? true : false;

export const equalsTrue = val => val === true;

export const dateIsValid = val => dayjs(val).isValid();

export const validateProperty = (value, property, model) => {
    let propertyModel = model[property];
    for (let validator of propertyModel.validators) {
        if (!validator(value, model))
            return false;
    }
    return true;
}

export const validateForm = (model, setModel) => {
    for (let [key, entry] of Object.entries(model)) {
        entry.validationMessage = null;
        entry.isValid = validateProperty(entry.value, key, model);
    }
    setModel({ ...model });
    let isValid = Object.values(model).every(x => x.isValid);
    if (!isValid) {
        enqueueSnackbar(i18n.t("CheckTheFieldsMarkedRed"), { variant: "error" });
        for (let msg of Object.values(model).filter(x => x.validationMessage != null))
            enqueueSnackbar(i18n.t(msg.validationMessage), { variant: "error" });
    }
    return isValid;
}