import * as React from 'react';
import { Container, Box, ThemeProvider } from '@mui/material';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import './App.css';
import { mainTheme } from './Themes';
import Spinner from './reusable-components/Spinner/Spinner';
import { SnackbarProvider } from 'notistack';

function App() {
  const [loading, setLoading] = React.useState(false);

  return (
    <ThemeProvider theme={mainTheme}>
      <SnackbarProvider preventDuplicate autoHideDuration={3000} maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Spinner open={loading} />
        <Header />
        <Container maxWidth="md">
          <Box sx={{ my: 4 }}>
            <Main setLoading={setLoading} />
            <Footer />
          </Box>
        </Container>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
