import * as ValidationHelper from './ValidationHelper';

export const updateProperty = (value, property, model, setModel) => {
    let propertyModel = model[property];
    propertyModel.isValid = ValidationHelper.validateProperty(value, property, model);
    propertyModel.value = value;
    setModel({ ...model });
}

export const extractValues = model => {
    let valueModel = {};
    for (let [key, property] of Object.entries(model)) {
        try {
            if (property.value !== null && property.value !== undefined && property.value.isValid !== undefined && property.value.isValid())
                valueModel[key] = property.value.format('YYYY-MM-DD');
            else
                valueModel[key] = property.value;
        } catch { }
    }
    return valueModel;
}